import { FC } from "react";
import unumBlueLogo from "../../../assets/icons/unumBlueLogo.svg";
import HelpButton from "../HelpButton/HelpButton";
import style from "./TopMenu.module.scss";
type topMenuProps = {
  title: string;
  showHelp: boolean;
  isHomePage: boolean;
};

const TopMenu: FC<topMenuProps> = ({ title, showHelp, isHomePage = false }) => {
  return (
    <div className={style.TM_Main}>
      <div className={style.TM_Left}>
        {isHomePage ? ( //182820 - Need to show unum logo instead header title if it is home page.
          <div className={style.TM_Logo}>
            <img
              data-testid="IMG_Unum_Blue_Logo"
              src={unumBlueLogo}
              alt="Unum Logo"
            />
          </div>
        ) : (
          <h1 data-autoid="H1_Layout_Title" className={style.TM_Title + " h3"}>
            {title}
          </h1>
        )}
      </div>
      <div className={style.TM_Right}>
        <div className={style.TM_HelpWrapper} data-testid="Help_Button">
          {showHelp && !isHomePage && <HelpButton />}
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
