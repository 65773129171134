import { useNavigate } from "react-router-dom";
import APP_URL_CONSTS from "../../navigation/AppUrls";
import { BentoBox } from "../../components/Storybook/BentoBox/BentoBox";
import { SimpleText } from "../../components/Storybook/SimpleText/SimpleText";
import Greetings from "../../components/Storybook/Greeting/Greetings";
import { PromotionalBanner } from "../../components/Storybook/PromotionalBanner/PromotionalBanner";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useAppSelector } from "./../../hooks/reduxhooks";
import { CmsType } from "../../types/enums/CmsType";
import useGetUser from "../../hooks/useGetUser";
import useFetchHomepageData from "../../hooks/CMS/useFetchHomepage";
import CarouselSection from "./CarouselSection/CarouselSection";
import Footer from "components/Storybook/Footer/Footer";
import { useState } from "react";
import TermAndConditionsDialog from "components/TermAndConditionsDialog/TermAndConditionsDialog";
import useTermsandCondtions from "hooks/TnC/useTermsAndCondtions";
import UnumStorySection from "./UnumStorySection/UnumStorySection";

const Homepage = () => {
    const navigate = useNavigate();
    const { homepageData, cmsDataError } = useFetchHomepageData(CmsType.Kontent);
    const callbackFunction = () => {
        window.open(`${homepageData?.promoBanner.link}`);
    };
    const { hasCashPlanAccess, hasEmployerAccess, user } = useGetUser();
    const bentoBoxVariant = hasCashPlanAccess && hasEmployerAccess ? "4" : "3";

    const [showTnC, setShowTnC] = useState<boolean>(false);
    const { tncDocument, loadingTnc, errorLoadingTnc, setErrorloadingTnc } =
      useTermsandCondtions();

    const welcomeComponents = [];

    const isCmsLoading = useAppSelector(
        (state) => state.CmsLoadingSlice.isCmsLoading
    );

    //Redirect user if disabled
    if (process.env.REACT_APP_USE_HOMEPAGE === "false") {
        navigate("/claims");
    }

    //#region Welcome components
    welcomeComponents.push(
        isCmsLoading ? (
            <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
                <Skeleton
                    variant="text"
                    sx={{ fontSize: "25px", height: "100%", width: "100%" }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: "100px", height: "100%", width: "100%" }}
                />
            </Stack>
        ) : (
            <Greetings
                body={homepageData?.intro.welcomeMessage}
                initialGreeting="Welcome"
                name={user.firstName}
            />
        )
    );

    // PromotionalBanner component
    welcomeComponents.push(
        isCmsLoading ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
            <PromotionalBanner
                header={homepageData?.promoBanner.title ?? ""}
                headerAltText={homepageData?.promoBanner.title ?? ""}
                image={homepageData?.promoBanner.imageUrl ?? ""}
                subHeader={homepageData?.promoBanner.subtitle ?? ""}
                CallBack={homepageData?.promoBanner.link ? callbackFunction : undefined}
            />
        )
    );

    // SimpleText component for GroupClaimsAccess
    if (hasEmployerAccess) {
        welcomeComponents.push(
            isCmsLoading ? (
                <Skeleton variant="rounded" width={700} height={250} />
            ) : (
                <SimpleText
                    RedirectCallBack={() => {
                        navigate(`${APP_URL_CONSTS.CLAIMS}`);
                    }}
                    body={homepageData?.groupRiskProductPromo.subtitle ?? ""}
                    initialGreetings={homepageData?.groupRiskProductPromo.title ?? ""}
                    variant="primary"
                />
            )
        );
    }
    // SimpleText component for CashplanAccess
    if (hasCashPlanAccess) {
        welcomeComponents.push(
            isCmsLoading ? (
                <Skeleton variant="rounded" width={700} height={250} />
            ) : (
                <SimpleText
                    RedirectCallBack={() => {
                        window.open(homepageData?.cashPlanProductPromo.link, "_blank");
                    }}
                    body={homepageData?.cashPlanProductPromo.subtitle ?? ""}
                    initialGreetings={homepageData?.cashPlanProductPromo.title ?? ""}
                    variant="secondary"
                />
            )
        );
    }
    // #endregion

    return (
      <>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "80px",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                padding: "40px",
            }}
        >
            <div>
                <Stack spacing={10} sx={{ height: "100%", width: "100%" }}>
                    <BentoBox variant={bentoBoxVariant}>{welcomeComponents}</BentoBox>
                    <CarouselSection
                        isCmsLoading={isCmsLoading}
                        carouselSectionData={homepageData?.CarouselSection}
                    />
                    <UnumStorySection
                        isCmsLoading={isCmsLoading}
                        UnumStory={homepageData?.UnumStory}
                        HealthCashPlan={homepageData?.HealthCashPlanStory}
                        hasCashPlanAccess={hasCashPlanAccess}
                        hasEmployerAccess={hasEmployerAccess}
                    />
                </Stack>
            </div>
        </div>
        <TermAndConditionsDialog
        isOpen={showTnC}
        setIsOpen={setShowTnC}
        tncDocument={tncDocument}
        isLoading={loadingTnc}
        tncError={errorLoadingTnc}
        setTncError={setErrorloadingTnc}
      />
      <Footer
        colourScheme="primary"
        slimVariant
        menuItems={[
          {
            label: "Terms of use",
            onClick: () => {
              setShowTnC(true);
            },
          },
          {
            label: "Privacy Notice",
            onClick: () => {
              window.open(
                "https://www.unum.co.uk/privacy-notice",
                "Privacy-notice"
              );
            },
          },
          {
            label: "Legal Notice",
            onClick: () => {
              window.open(
                "https://www.unum.co.uk/legal-notice",
                "legal-notice"
              );
            },
          },
          {
            label: "How we use cookies",
            onClick: () => {
              window.open(
                "https://www.unum.co.uk/how-we-use-cookies",
                "cookies"
              );
            },
          },
        ]}
      />
        </>
    );
};

export default Homepage;
