/* eslint no-useless-escape: "off" -- turn off the rule as the regex require the escapes*/
import { v4 as uuid } from "uuid";
import { logErrorToInsights, logToInsights } from "../ApplicationInsights";
import { getGIPClaim } from "../services/apis";
import apiCall from "../services/apiWrapper";
import { API_ERR_MSG } from "./Strings/API_Errors";
import { products } from "../types/enums/Products/Products";
import { CmsType } from "../types/enums/CmsType";

export const getUuid = () => {
  return uuid();
};

export const isValidPostCode = (postCode: string): boolean => {
  const regexp = new RegExp(
    `^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$`
  );
  return regexp.test(postCode.replace(" ", ""));
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numberRegex = /^[0-9]*$/;

export const nameHyphenRegex = /^[a-zA-Z\s-*]*$/;

export const nameRegex = /^[a-zA-Z\s-]*$/;

export const phoneNoRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const alphaNumericRegex = /^[a-zA-Z0-9]*$/;

export const sortcodeRegex = /^(\d{2}-){2}\d{2}$/;

export const currencyRegex = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;

export const radioGroupData = (value: string, label: string) => {
  return { value, label };
};

export const titleArray = () => {
  return [
    { label: "-- Please select --", value: "" },
    "Mr",
    "Mrs",
    "Miss",
    "Ms",
    "Mx",
    "Misc",
    "Msr",
    "Pr",
    "Ind",
    "Dr",
    "Lord",
    "Lady",
    "Sir",
    "Professor",
    "Captain",
    "Dame",
    "Father",
    "Rev",
  ].map((title: string | { value: string; label: string }) => {
    if (typeof title === "string") {
      return { value: title, label: title };
    }
    return title;
  });
};
/**
 * To get the date in YYYY-MM-DD
 * @param date
 * @returns
 */
export const getDateinYYYYMMDD = (date: Date): any => {
  try {
    return `${date.toLocaleString("default", {
      year: "numeric",
    })}-${date.toLocaleString("default", {
      month: "2-digit",
    })}-${date.toLocaleString("default", { day: "2-digit" })}`;
  } catch (error) {
    return false;
  }
};
/**
 * To get the date in DD/MM/YYYY
 * @param date
 * @returns
 */
export const getDateinWithSlash = (date: Date, locale = "default"): any => {
  try {
    return `${date.toLocaleString(locale, {
      day: "2-digit",
    })}/${date.toLocaleString(locale, {
      month: "2-digit",
    })}/${date.toLocaleString(locale, {
      year: "numeric",
    })}`;
  } catch (error) {
    return false;
  }
};

/**
 * To get the date in DD/MM/YYYY HH:MM:SS:mmm
 * @param date
 * @returns
 */
export const getDateForlogging = (date: Date, locale = "default"): any => {
  try {
    const datePart = `${date.toLocaleString(locale, {
      day: "2-digit",
    })}/${date.toLocaleString(locale, {
      month: "2-digit",
    })}/${date.toLocaleString(locale, {
      year: "numeric",
    })}`;

    const timePart = `${date.toLocaleString(locale, {
      hour: "2-digit",
    })}:${date.toLocaleString(locale, {
      minute: "2-digit",
    })}:${date.toLocaleString(locale, {
      second: "numeric",
    })}:${date.toLocaleString(locale, {
      fractionalSecondDigits: 3,
    })}`;

    return `${datePart} ${timePart} `;
  } catch (error) {
    return false;
  }
};

/**
 * Product types as per API
 * @param product
 * @returns
 */
export const getProductType = (product: string): number => {
  switch (product) {
    case "GIP":
      return 1;
    case "GIPVRS":
      return 4;
    case "GLI":
      return 3;
    case "GCI":
      return 2;
    default:
      return 0;
  }
};

export const getClaimData = async (claimId: string) => {
  const resp: any = await apiCall({
    ...getGIPClaim,
    headers: { ...getGIPClaim.headers, ClaimId: claimId },
  });
  return resp.response;
};

/*  It removes the empty properties from the data object passed as argument
 * @param data
 */
export const removeEmpty = (data: any) => {
  if (typeof data !== "object" || data === null) {
    // Only proceed if data is an object and not null
    return;
  }

  Object.keys(data).forEach((key) => {
    if (
      data[key] === null ||
      data[key] === "" ||
      (Array.isArray(data[key]) && data[key].length === 0) ||
      (typeof data[key] === "object" &&
        !Array.isArray(data[key]) &&
        Object.keys(data[key]).length === 0)
    ) {
      delete data[key];
    }
  });
};

export const radioGroupOptions = [
  radioGroupData("No", "No"),
  radioGroupData("Yes", "Yes"),
];

export const getRadioYesOrNo = (res: boolean | undefined): string => {
  if (res === undefined) {
    return "";
  }
  return res ? "Yes" : "No";
};

export const getRadioBoolean = (res: string | undefined) => {
  if (res === "Yes") {
    return true;
  } else if (res === "No") {
    return false;
  }
  return "";
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // as string and split removes some meta data
    reader.onload = () => resolve((reader.result as string).split(",")[1]);
    reader.onerror = reject;
  });
/**
 *
 * @param val Get the boolean value from Radio YES and NO
 * @returns
 */
export const show = (val: string): boolean => {
  return val === "Yes" ? true : false;
};

/**
 * Get YES or NO from boolean val
 * @param val
 * @returns
 */
export const getTxt = (val: boolean): string => {
  return val ? "Yes" : "No";
};

/**
 * To add the commas in the digits before .(decimal)
 * @param beforeDeicmal
 * @returns
 */
const generateCommas = (beforeDeicmal: string) => {
  if (
    beforeDeicmal.length > 3 &&
    Number(beforeDeicmal.replaceAll(",", "")) > 999
  ) {
    return beforeDeicmal
      .replaceAll(",", "")
      .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  return Number(beforeDeicmal.replaceAll(",", "")) + "";
};
/**
 * To convert the given value in to currency format eg: 2,345,567.89
 * @param val
 * @returns
 */
export const convertToCurrency = (val: string, symbol?: string) => {
  let inputValue = val;
  if (!inputValue || inputValue === "" || inputValue === undefined) {
    return "";
  }
  inputValue = inputValue.replace(/[^0-9,.]/g, "");
  let beforeDeicmal = "";

  if (inputValue.includes(".")) {
    beforeDeicmal = inputValue.split(".")[0].replace(",", "");
    let afterDecimal = inputValue.split(".")[1].replace(",", "");
    if (afterDecimal.length > 2) {
      afterDecimal = afterDecimal.substring(0, 2);
    }

    beforeDeicmal = generateCommas(beforeDeicmal);
    if (symbol) {
      return `${symbol}${beforeDeicmal}.${afterDecimal}`;
    }
    return `${beforeDeicmal}.${afterDecimal}`;
  }
  if (symbol) {
    return symbol + generateCommas(inputValue);
  }
  return generateCommas(inputValue);
};

export function daysToExpire(
  lastUpadated: Date,
  expiresInDays: number
): number {
  if (lastUpadated === null || lastUpadated === undefined) {
    return 0;
  }
  const expiryDate = new Date(lastUpadated);
  const currentDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + expiresInDays);
  const diffTime = expiryDate.getTime() - currentDate.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const isEmpty = (val: any): boolean => {
  return val === null || val === "" || val === undefined || val.length === 0;
};

export const getGlobalError = (err: any): string => {
  return err?.errorList?.[0]?.errorMessage || API_ERR_MSG;
};

export const getTransId = (params: any) => {
  return (
    params?.headers?.transactionId ||
    params?.headers?.TransactionId ||
    params?.headers?.Transactionid ||
    params?.headers?.TransactionID ||
    params?.data?.transactionId ||
    params?.data?.TransactionId ||
    params?.data?.Transactionid ||
    params?.data?.TransactionID
  );
};

export const formatDate = (date: any) => {
  const day = String(date.getDay()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const logNodeName = "EmployerPortalUI";

export const checkAndLogErr = (result: any, params: any, data?: compData) => {
  const currentDate = getDateForlogging(new Date());
  try {
    if (result.data.resultCode === 0) {
      logToInsights(
        `${getTransId(params)},${currentDate},${logNodeName},${
          params.headers?.fileName || ""
        },${data?.company || ""},${data?.product || ""},${""},${params.url},${
          params?.method
        },${result?.status},${result?.statusText}`
      );
    } else {
      logErrorToInsights(
        `${getTransId(params)},${currentDate},${logNodeName},${
          params.headers?.fileName || ""
        },${data?.company || ""},${data?.product || ""},${""},${params.url},${
          params?.method
        },${result?.status},${result?.data.errorList?.[0]?.errorMessage || result?.statusText}`
      );
    }
  } catch (err) {}
};

export const getSixdigitsClaimId = (claimId?: string) => {
  if (!claimId) {
    return undefined;
  }
  return claimId?.trim()?.length < 6 ? claimId?.padStart(6, "0") : claimId;
};

export type compData = {
  company: string;
  product: string;
};

export const setRehabStatus = (status?: string, isTracking = false) => {
  const params = isTracking ? "case" : "closed";

  if (!status) {
    return "";
  }

  if (status.toLowerCase().includes("no rehab")) {
    return `${
      isTracking ? "Case closed" : "Closed"
    } - No absence management required`;
  }

  if (status.toLowerCase().includes("case closed")) {
    // i.e. Tracking: "Case closed - Agreed outcome"
    // i.e. Dashboard: "Closed - Agreed outcome"
    const text = status?.substring(status?.indexOf(params));

    const textArr = text?.split("-");
    const firstPart = textArr[0].charAt(0).toUpperCase() + textArr[0].slice(1);
    const secondPart =
      textArr[1].trimStart().charAt(0).toUpperCase() +
      textArr[1].trimStart().slice(1);
    if (secondPart.includes("Agreed outcome")) {
      return `${firstPart.trim()} - Outcome agreed`;
    }
    return `${firstPart.trim()} - ${secondPart}`;
  }
  return status;
};

export const getProductLabelFromAPIValue = (val: string) => {
  if (val === "GIPVRS") {
    return "Absence management";
  } else if (val === "GLI") {
    return products.GL.label;
  } else if (val === "GCI") {
    return products.CIC.label;
  } else if (val === "GIP") {
    return products.GIP.label;
  } else {
    return val;
  }
};

export const emailToName = (email: string) => {
  if (!email) return "";
  const [username] = email.split("@");

  let names = username.split(".");

  names = names.map((name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  });

  return names.join(" ");
};

export function isValidCmsType(value: any): boolean {
  return (Object.values(CmsType) as string[]).includes(value);
}

export function removeObjectTags(inputString: string) {
  const regex = /<object[^>]*>.*?<\/object>/gs;
  return inputString.replace(regex, '');
}

export function extractDataCodename(inputString: string) {
  const regex = /<object[^>]*data-codename="([^"]*)"[^>]*>/;
  const match = inputString.match(regex);
  return match ? match : null;
}
export const getDateDMY = (date: Date) => {
  const weekday = date.toLocaleString("default", { weekday: "short" });

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${weekday}, ${day} ${month} ${year}`;
};
