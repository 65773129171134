import { css } from "@emotion/react";
import styled from "styled-components";

export const ScrollFade = styled.div<{ showFade: boolean }>`
  position: sticky;
  bottom: 0;
  height: 40px;
  ${({ showFade }) =>
    showFade &&
    css`
      background: rgb(248, 248, 248);
      background: linear-gradient(
        180deg,
        rgba(248, 248, 248, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    `}

  display: flex;
  justify-content: center;
  align-items: center;
`;
