import MuiBox from "@mui/material/Box";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import styled, { css } from "styled-components";

export const Box: any = styled(MuiBox)<{
  $orientation: string;
  $variant: string;
}>`
  max-width: 100%;
  && {
    background: #ffffff;
    border-radius: 40px;
    padding: 12px;
  }

  ${({ $variant, $orientation }) =>
    $variant === "scrollable" &&
    $orientation !== "horizontal" &&
    css`
      height: 265px;
      flexgrow: 1;
      display: flex;
    `}

  ${({ $variant }) =>
    $variant === "standard" &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const StyledTabs: any = styled(MuiTabs)`
  && {
  }
`;

export const Tab: any = styled(MuiTab)`
  && {
    color: #000000;
    font-weight: 600;
    height: 48px;
    border-radius: 24px;

    &.Mui-selected {
      color: #ffffff;
      background-color: #015294;
    }
    &:focus-visible {
      outline: 1px solid black;
      outline-offset: -1px;
    }
  }
`;
