import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IGenericHomepage } from '../../models/cms/kontent_model/Generic/interfaces';
import { setCMSLoading } from '../../redux/slices/cmsLoadingSlice';
import useFetchCMSData from "../fetchCMSData";
import { CmsType } from '../../types/enums/CmsType';
import { MapHomepageData } from '../../models/cms/kontent_model/Mapping/KontentToGenericMapping';
import { isValidCmsType } from '../../utils/util';
import { kontentEventEnum } from "../../types/enums/CarouselEnums";
const useFetchHomepageData = (cms: CmsType) => {
  const [homepageData, setHomepageData] = useState<IGenericHomepage | null>(
    null
  );
  const [cmsDataError, setCMSDataError] = useState<string | null>(null);

  const dispatch = useDispatch();
  const { fetchData } = useFetchCMSData();
  const unumUkQueryParams = new URLSearchParams();
  unumUkQueryParams.append(
    "system.type[in]",
    `${kontentEventEnum.podcast},${kontentEventEnum.event}`
  );
  const { fetchData: fetchUnumUkData } = useFetchCMSData(
    "UnumUK",
    unumUkQueryParams
  );

  const fetchAndMapData = useCallback(async () => {
    dispatch(setCMSLoading(true));

    //Don't proceed if the CMS isn't even supported
    if (!isValidCmsType(cms)) {
      setCMSDataError("CMS not supported");
      dispatch(setCMSLoading(false));
      return { response: null, cmsDataError };
    }
    const { response: unumUkResponse, error: unumUkError } =
      await fetchUnumUkData();
    const { response, error } = await fetchData();

    if (error || unumUkError) {
      setCMSDataError(error);
      dispatch(setCMSLoading(false));
      return { response: null, error };
    }

    if (cms === CmsType.Kontent) {
      const { homepageGeneric, cmsDataError } = MapHomepageData(
        response,
        unumUkResponse
      );
      if (cmsDataError) {
        setCMSDataError(cmsDataError);
        dispatch(setCMSLoading(false));
        return { response: null, cmsDataError };
      } else {
        setHomepageData(homepageGeneric);
      }
    } else {
      setCMSDataError("CMS not supported");
      dispatch(setCMSLoading(false));
      return { response: null, cmsDataError };
    }

    dispatch(setCMSLoading(false));
    return { response, error: null };
  }, [cms, fetchData, fetchUnumUkData, dispatch]);

  useEffect(() => {
    fetchAndMapData();
  }, []);

  return { homepageData, cmsDataError };
};

export default useFetchHomepageData;
