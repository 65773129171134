import { ComponentFeature, ComponentProductPromo, ComponentPromo, MediaAsset, NavExternalLink, PageHome } from "../content-types";
import { ICarouselSection, IGenericHomepage } from "../Generic/interfaces";
import { homepageCodenames } from "../../../../types/enums/KontentAiCodenames/codenames";
import { extractDataCodename, removeObjectTags } from "../../../../utils/util"
import { MediaType } from "../../../../types/enums/KontentAiCodenames/mediaformats";
import { assetAlignment } from "../../../../types/enums/KontentAiCodenames/assetalignments";
import { mapCarouselItems } from "./KontentMapCarouselItems";
import { UiCarouselItemTypeEnum } from "../../../../types/enums/CarouselEnums";

const mapCarousel = (
    responseData: any,
    unumUkResponse: any
): ICarouselSection => {
    const artical = responseData?.items?.find(
        (item: any) =>
            item?.system.codename === homepageCodenames.latestArticalsFeed.codename
    );
    const podcasts = responseData?.items?.find(
        (item: any) =>
            item?.system.codename === homepageCodenames.podcastsFeed.codename
    );
    const wellbeingWebinars = responseData?.items?.find(
        (item: any) =>
            item?.system.codename === homepageCodenames.wellbeingWebinarsFeed.codename
    );

  const carouselItems = mapCarouselItems(unumUkResponse);
  const podcastsItems = carouselItems
    .filter((item) => item.eventType === UiCarouselItemTypeEnum.podcast)
    .sort(function (i, j) {
      return new Date(j.date).getTime() - new Date(i.date).getTime();
    })
    .slice(0, 10);
  const wellbeingWebinarsItems = carouselItems
    .filter((item) => {
      return (
        item.eventType === UiCarouselItemTypeEnum.webinar &&
        new Date(item.date) >= new Date()
      );
    })
    .sort(function (i, j) {
      return new Date(i.date).getTime() - new Date(j.date).getTime();
    })
    .slice(0, 10);
  const latestArticlesItems = carouselItems
    .filter((item) => item.eventType === UiCarouselItemTypeEnum.usefulResource)
    .sort(function (i, j) {
      return new Date(j.date).getTime() - new Date(i.date).getTime();
    })
    .slice(0, 10);

  return {
    wellbeingWebinars: {
      id: 0,
      title: wellbeingWebinars.elements.title.value,
      subtitle: wellbeingWebinars.elements.subtitle.value,
      feed: wellbeingWebinarsItems,
    },
    articles: {
      id: 1,
      title: artical.elements.title.value,
      subtitle: artical.elements.subtitle.value,
      feed: latestArticlesItems,
    },
    podcasts: {
      id: 2,
      title: podcasts.elements.title.value,
      subtitle: podcasts.elements.subtitle.value,
      feed: podcastsItems,
    },
  };
};

export const MapHomepageData = (responseData: any, unumUkResponse?: any) => {
    let homepageGeneric: IGenericHomepage | null = null;
    let cmsDataError: string | null = null;

    const homepageItem = responseData?.items?.find(
        (item: any) => item?.system.codename === homepageCodenames.homepage.codename
    );
    const kontentHomePage: PageHome = homepageItem;

    if (kontentHomePage && kontentHomePage.elements && responseData.modular_content) {
        const promoBannerItem = responseData.items?.find(
            (element: any) =>
                element?.system.codename === homepageCodenames.rehabBanner.codename
        );
        const kontentPromoBannerObject: ComponentPromo = promoBannerItem;

        //PromoBannerImage
        const promoBannerData = responseData.items?.find(
            (element: any) =>
                element?.system.codename ===
                kontentPromoBannerObject.elements.asset.value[0]
        );
        const promoBannerImageData: MediaAsset = promoBannerData;
        //PromoBanner Link
        const promoBannerLinkData = responseData.items?.find(
            (element: any) =>
                element?.system.codename ===
                kontentPromoBannerObject?.elements?.link?.value[0]
        );
        const promoBannerLink: NavExternalLink = promoBannerLinkData;

        //GroupRisk Product Promo
        const groupRiskproductPromoItem = responseData.items?.find(
            (element: any) =>
                element?.system.codename ===
                homepageCodenames.groupRiskProductPromo.codename
        );
        const kontentgroupRiskProductObject: ComponentProductPromo =
            groupRiskproductPromoItem;

        const groupRiskproductPromoData = responseData.items?.find(
            (element: any) =>
                element?.system.codename ===
                kontentgroupRiskProductObject.elements.link.value[0]
        );
        const groupRiskproductLinkData: NavExternalLink = groupRiskproductPromoData;

        //CashPlanProduct Promo
        const cashPlanproductPromoItem = responseData.items?.find(
            (element: any) =>
                element?.system.codename ===
                homepageCodenames.cashPlanProductPromo.codename
        );
        const kontentcashPlanProductObject: ComponentProductPromo =
            cashPlanproductPromoItem;

        const cashPlanproductPromoData = responseData.items?.find(
            (element: any) =>
                element?.system.codename ===
                kontentcashPlanProductObject.elements.link.value[0]
        );
        const cashPlanproductLinkData: NavExternalLink = cashPlanproductPromoData;

        //The Unum Story
        const theUnumStory = responseData.items?.find((element: any) => element?.system.codename === homepageCodenames.theUnumStory.codename);
        const kontentUnumStoryAsset: ComponentFeature = theUnumStory;

        //Health Cash plan section
        const healthCashPlanStory = responseData.items?.find((element: any) => element?.system.codename === homepageCodenames.healthCashPlan.codename);
        const kontentHealthCashPlanAsset: ComponentFeature = healthCashPlanStory;

        let mediaUrlValue: string | undefined;
        const mediaAsset = responseData.items?.find((element: any) => element?.system.codename === kontentUnumStoryAsset.elements.asset.value[0]);
        if (mediaAsset.system.type === MediaType.EXTERNAL_VIDEO) {
            const videoAsset = responseData.items?.find((element: any) => element?.system.codename === kontentUnumStoryAsset.elements.asset.value[0]);
            mediaUrlValue = videoAsset.elements.url.value;
        }
        else {
            const imageAsset = responseData.items?.find((element: any) => element?.system.codename === kontentUnumStoryAsset.elements.asset.value[0]);
            mediaUrlValue = imageAsset.elements.image.value[0].url;
        }

        let cashplanMediaUrlValue: string | undefined;
        const cashplanMediaAsset = responseData.items?.find(
          (element: any) =>
            element?.system.codename ===
            kontentHealthCashPlanAsset.elements.asset.value[0]
        );
        if (cashplanMediaAsset.system.type === MediaType.EXTERNAL_VIDEO) {
          const cashplanVideoAsset = responseData.items?.find(
            (element: any) =>
              element?.system.codename ===
              kontentHealthCashPlanAsset.elements.asset.value[0]
          );
          cashplanMediaUrlValue = cashplanVideoAsset.elements.url.value;
        } else {
          const cashPlanimageAsset = responseData.items?.find(
            (element: any) =>
              element?.system.codename ===
              kontentHealthCashPlanAsset.elements.asset.value[0]
          );
          cashplanMediaUrlValue =
            cashPlanimageAsset.elements.image.value[0].url;
        }

        let UnumStoryButtonDataObject: any;
        if (kontentUnumStoryAsset.elements.body.value) {
          const UnumStorybuttonCodename = extractDataCodename(
            kontentUnumStoryAsset.elements.body.value
          );
          if (UnumStorybuttonCodename) {
            UnumStoryButtonDataObject =
              responseData?.modular_content?.[UnumStorybuttonCodename[1]];
          }
        }

        let HealthCashPlanStoryButtonDataObject: any;
        if (kontentHealthCashPlanAsset.elements.body.value) {
          const HealthCashPlanStorybuttonCodename = extractDataCodename(
            kontentHealthCashPlanAsset.elements.body.value
          );
          if (HealthCashPlanStorybuttonCodename) {
            HealthCashPlanStoryButtonDataObject =
              responseData?.modular_content?.[
                HealthCashPlanStorybuttonCodename[1]
              ];
          }
        }

        const data: IGenericHomepage = {
          intro: {
            welcomeMessage: kontentHomePage.elements.intro.value,
          },
          promoBanner: {
            imageUrl: promoBannerImageData.elements.image.value[0].url,
            ariaLabel: promoBannerImageData.elements.caption.value,
            title: kontentPromoBannerObject.elements.title.value,
            subtitle: kontentPromoBannerObject.elements.subtitle.value,
            link: promoBannerLink?.elements?.url?.value,
          },
          groupRiskProductPromo: {
            title: kontentgroupRiskProductObject.elements.title.value,
            subtitle: kontentgroupRiskProductObject.elements.subtitle.value,
            link: groupRiskproductLinkData.elements.url.value,
            product:
              kontentgroupRiskProductObject.elements.product.value[0].name,
          },
          cashPlanProductPromo: {
            title: kontentcashPlanProductObject.elements.title.value,
            subtitle: kontentcashPlanProductObject.elements.subtitle.value,
            link: cashPlanproductLinkData.elements.url.value,
            product:
              kontentcashPlanProductObject.elements.product.value[0].name,
          },
          //Trailing assets are right aligned for media contents
          UnumStory: {
            title: kontentUnumStoryAsset.elements.title.value,
            text: removeObjectTags(
              kontentUnumStoryAsset.elements.body.value.toString()
            ),
            rightAligned:
              kontentUnumStoryAsset.elements.layout.value[0].codename ===
              assetAlignment.trailing_asset,
            mediaURL: mediaUrlValue,
            mediaType: mediaAsset.system.type,
            buttonData: {
              url: UnumStoryButtonDataObject.elements.url.value,
              text: UnumStoryButtonDataObject.elements.label.value,
            },
          },
          HealthCashPlanStory: {
            title: kontentHealthCashPlanAsset.elements.title.value,
            text: removeObjectTags(
              kontentHealthCashPlanAsset.elements.body.value.toString()
            ),
            rightAligned:
              kontentHealthCashPlanAsset.elements.layout.value[0].codename ===
              assetAlignment.trailing_asset,
            mediaURL: cashplanMediaUrlValue,
            mediaType: cashplanMediaAsset.system.type,
            buttonData: {
              url: HealthCashPlanStoryButtonDataObject.elements.url.value,
              text: HealthCashPlanStoryButtonDataObject.elements.label.value,
            },
          },
          CarouselSection: mapCarousel(responseData, unumUkResponse),
        };
        homepageGeneric = data;
    } else {
        cmsDataError = "Homepage could not be found";
    }
    return { homepageGeneric, cmsDataError };
};