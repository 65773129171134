import { LogoProps } from "./Logo.types";
import { ReactComponent as unumLogoBlue } from "../../../assets/icons/unumLogoBlue.svg";
import { ReactComponent as unumLogoWhite } from "../../../assets/icons/unumLogoWhite.svg";
import { ReactComponent as unumLogoBlueShort } from "../../../assets/icons/unumLogoShortBlue.svg";
import { ReactComponent as unumLogoWhiteShort } from "../../../assets/icons/unumLogoShortWhite.svg";
import { ApplyBorder } from "./logo.styles";

const sizeStyles = {
  small: { width: 150, height: 30 },
  medium: { width: 150, height: 34 },
  large: { width: 150, height: 44 },
};

const sizeStylesSmall = {
  small: { width: 24, height: 24 },
  medium: { width: 32, height: 32 },
  large: { width: 48, height: 48 },
};

export const Logo = ({
  size = "medium",
  colour = "blue",
  sizeVariant,
  toggleLine = false,
}: LogoProps) => {
  let IconGraphic;
  let iconSizeHeight;
  let iconSizeWidth;

  if (sizeVariant) {
    IconGraphic = colour === "blue" ? unumLogoBlueShort : unumLogoWhiteShort;
    iconSizeHeight = sizeStylesSmall[size].height;
    iconSizeWidth = sizeStylesSmall[size].width;
  } else {
    IconGraphic = colour === "blue" ? unumLogoBlue : unumLogoWhite;
    iconSizeHeight = sizeStyles[size].height;
    iconSizeWidth = sizeStyles[size].width;
  }

  return (
    <ApplyBorder
      applyStyle={toggleLine}
      colour={colour}
      sizeVariant={sizeVariant}
      data-testid="logo"
    >
      <IconGraphic width={iconSizeWidth} height={iconSizeHeight} />
    </ApplyBorder>
  );
};

export default Logo;
