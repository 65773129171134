import { Button, Skeleton, Stack } from "@mui/material";
import { BentoBox } from "components/Storybook/BentoBox/BentoBox";
import Greetings from "components/Storybook/Greeting/Greetings";
import { PromotionalBanner } from "components/Storybook/PromotionalBanner/PromotionalBanner";
import VideoUploader from "components/Storybook/VideoUploader/VideoUploader";
import { IStoryItem } from "models/cms/kontent_model/Generic/interfaces";
import { FC } from "react";
import { MediaType } from "types/enums/KontentAiCodenames/mediaformats";
import styles from "../Homepage.module.scss";


type UnumStorySectionProps = {
    hasCashPlanAccess: boolean;
    hasEmployerAccess: boolean;
    isCmsLoading: boolean;
    UnumStory?: IStoryItem;
    HealthCashPlan?: IStoryItem;
};

const UnumStorySection: FC<UnumStorySectionProps> = ({
    hasCashPlanAccess,
    hasEmployerAccess,
    isCmsLoading,
    UnumStory,
    HealthCashPlan,
}) => {
    const promotionalUnumStory = [];
    const promotionalCashplan = [];
    const promotionalCashplanOrientation =
        hasCashPlanAccess && hasEmployerAccess ? "right" : "left";

    //#region Promotional content
    const promotionalGIPWidget1 = (
      <div>
        <Greetings initialGreeting={UnumStory?.title} body={UnumStory?.text} />
        <br />
        {/* TO DO: Swap button for storybook version */}
        <Button
          sx={{ padding: "12px 24px" }}
          onClick={() => {
            window.open(UnumStory?.buttonData?.url, "_blank");
          }}
          className={styles.featureButton}
        >
          {UnumStory?.buttonData?.text}
        </Button>
      </div>
    );

    const promotionalGIPWidget3 =
      UnumStory?.mediaType === MediaType.EXTERNAL_VIDEO ? (
        <VideoUploader videoUrl={UnumStory?.mediaURL} maxVideoWidth={900} />
      ) : (
        <PromotionalBanner image={UnumStory?.mediaURL || ""} />
      );

    if (hasEmployerAccess) {
      promotionalUnumStory.push(
        isCmsLoading ? (
          <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
            <Skeleton
              data-testid="unumStory-Skeleton"
              variant="text"
              sx={{ fontSize: "25px", height: "100%", width: "100%" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "100px", height: "100%", width: "100%" }}
            />
          </Stack>
        ) : UnumStory?.rightAligned ? (
          promotionalGIPWidget1
        ) : (
          promotionalGIPWidget3
        )
      );

      promotionalUnumStory.push(
        isCmsLoading ? (
          <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "25px", height: "100%", width: "100%" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "100px", height: "100%", width: "100%" }}
            />
          </Stack>
        ) : UnumStory?.rightAligned ? (
          promotionalGIPWidget3
        ) : (
          promotionalGIPWidget1
        )
      );
    }
    //Do-do with kontent hook - Replace video-uploader with promotional banner if image is returned instead of video
    const promotionalCashplanWidget1 =
      HealthCashPlan?.mediaType === MediaType.EXTERNAL_VIDEO ? (
        <VideoUploader
          videoUrl={HealthCashPlan?.mediaURL}
          maxVideoWidth={900}
        />
      ) : (
        <PromotionalBanner image={HealthCashPlan?.mediaURL || ""} />
      );
    const promotionalCashplanWidget2 = (
      <div>
        <Greetings
          initialGreeting={HealthCashPlan?.title}
          body={HealthCashPlan?.text}
        />
        <br />
        {/* TO DO: Swap button for storybook version */}
        <Button
          data-testid="healthCashPlanStory-Skeleton"
          sx={{ padding: "12px 24px" }}
          onClick={() => {
            window.open(HealthCashPlan?.buttonData?.url, "_blank");
          }}
          className={styles.featureButton}
        >
          {HealthCashPlan?.buttonData?.text}
        </Button>
      </div>
    );

    if (hasCashPlanAccess) {
        //The layout of the cashplan area changes depending if the groupLife promotional is rendered
        promotionalCashplan.push(
            isCmsLoading ? (
                <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
                    <Skeleton data-testid="cashPlanStory-Skeleton"
                        variant="text"
                        sx={{ fontSize: "25px", height: "100%", width: "100%" }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "100px", height: "100%", width: "100%" }}
                    />
                </Stack>
            ) : hasEmployerAccess && hasCashPlanAccess ? (
                promotionalCashplanWidget1
            ) : (
                promotionalCashplanWidget2
            )
        );

        promotionalCashplan.push(
            isCmsLoading ? (
                <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "25px", height: "100%", width: "100%" }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "100px", height: "100%", width: "100%" }}
                    />
                </Stack>
            ) : hasEmployerAccess && hasCashPlanAccess ? (
                promotionalCashplanWidget2
            ) : (
                promotionalCashplanWidget1
            )
        );
    }

    return (
        <>
            {hasEmployerAccess && (
                <BentoBox variant={"3"}>{promotionalUnumStory}</BentoBox>
            )}
            {hasCashPlanAccess && (
                <BentoBox
                    variant={"3"}
                    itemOrientation={promotionalCashplanOrientation}
                >
                    {promotionalCashplan}
                </BentoBox>
            )}
        </>
    );

}
export default UnumStorySection;
