import styled, { css } from "styled-components";

export const VideoBlock = styled.div`
  width: 100%;
  height: 100%;
  border-radius: var(--Spacing-1, 4px);
  background: #fff;

  /* Shadow/Card */
  box-shadow: 0px 2px 4px 0px rgba(15, 40, 53, 0.2);
`;