import styled from "styled-components";

export const TncHeader = styled.div`
  position: sticky;
  color: #015294;
  font-size: 28px;
  font-weight: 500;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #cdcdcd solid;
  margin-bottom: 24px;
`;

export const TncWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const TncActionsWrapper = styled.div`
  position: sticky;
  color: #015294;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const TncActions = styled.div`
  background: white;
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 20px;
  background-color: #eeebeb;
  border-top: 1px solid #cdcdcd;
`;

export const TnCButton = styled.button`
  border: 1px solid #015294;
  background: #ffffff;
  padding: 8px 16px;
  color: #015294;
  cursor: pointer;
  border-radius: 10000px;
`;

export const TnCData = styled.div`
  max-width: 820px;
  padding: 20px;
`;