import React from "react";
import {
  StyledGreetingBody,
  StyledGreeting,
  StyledGreetingBold,
  StyledHeadingContainer,
} from "./Greetings.styles";
import DOMPurify from "dompurify";

type GreetingsProps = {
  initialGreeting?: string;
  name?: string;
  body?: string;
};

const Greetings: React.FC<GreetingsProps> = ({
  initialGreeting,
  name,
  body,
}) => {
  return (
    <div>
      <StyledHeadingContainer>
        <StyledGreeting>{initialGreeting}</StyledGreeting>
        <StyledGreetingBold>{name}</StyledGreetingBold>
      </StyledHeadingContainer>

      <StyledGreetingBody
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body || '') }}
      />
    </div>
  );
};
export default Greetings;
