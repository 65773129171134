export enum kontentCarouselItemEnum {
  usefulResource = "event_type__useful_resource",
  awarenessDate = "event_type__awareness_date",
  webinar = "event_type__webinar",
}

export enum UiCarouselItemTypeEnum {
  "awarenessDate" = "awarenessDate",
  "webinar" = "webinar",
  "usefulResource" = "usefulResource",
  "podcast" = "podcast",
}

export enum kontentEventEnum {
  "event" = "event",
  "podcast" = "podcast",
}
