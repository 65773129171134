import { FC } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

type DialogWithCloseProps = {
  onClose: () => void;
} & DialogProps;

const DialogWithClose: FC<DialogWithCloseProps> = (props) => {
  return (
    <Dialog {...props} PaperProps={{ sx: { borderRadius: "6px" } }}>
      {props.onClose ? (
        <IconButton
          aria-label="Exit button"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      ) : undefined}

      {props.children}
    </Dialog>
  );
};

export default DialogWithClose;
