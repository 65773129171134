export const POST_CODE_ERR_MSG = "Please enter valid postcode";

export const labelsObj: any = {
  //Employee Details
  title: "Title",
  pronoun: "Pronoun(s)",
  firstName: "First name",
  lastName: "Last name",
  dateOfBirth: "Date of birth",
  dateOfDeath: "Date of death",
  placeOfDeath: "Place of death",
  causeOfDeath: "Cause of death",
  jobTitle_Deceased: "Job title",
  phoneNumber: "Phone number",
  personalEmailAddress: "Personal email address",
  workEmailAddress: "Work email address",
  consentToContactEmployee: "Consent to contact employee",
  consentToEmployeeContactTypesModel: "Contact type",
  nonUKIndicator: "Non - UK",
  postcode: "Postcode",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  addressLine3: "Town",
  addressLine4: "County",
  country: "Country",

  //Work details
  jobTitle: "Employee's job title",
  weeklyHours: "What are the employee’s normal weekly contracted hours?",
  basicAnnualSalary: "Basic annual salary prior to incapacity",
  hasTravelRole: "Is travel a material requirement of the role?",
  annualMileageFrquency:
    "Please describe the frequency and duration of business journeys",
  modeOfTravel: "How is the travel undertaken?",
  workPlaceLocation: "Employee’s main workplace location",
  dailyDutiesDescription:
    "Daily duties description, including essential skills",

  // Eligibility details
  unableToWorkFromDate: "Date employee first became unable to work",
  repeatedAbsenceInLastTweleveMonths:
    "Has the employee been absent for the same reason in the last 12 months? If yes, please upload absence records on the last page of the claim submission",
  companyJoinDate: "Date the employee joined the company",
  schemeJoinDate: "Date the employee joined the Group Income Protection scheme",
  hasEmploymentBeenTerminated: "Has employment ended?",
  employmentTerminatedDate: "When did employment end?",
  sickPayCeaseDate: "When will/did your employee’s sick pay cease?",
  hasDateOfBirthConfirmed:
    "Have you confirmed the employee's date of birth for employment or pension purposes?",
  isPensionPartOfScheme:
    "Is pension membership part of the Group Income Protection scheme’s eligibility criteria?",
  dateJoinedPensionScheme: "Date joined pension scheme",
  dateEligiblePensionScheme: "Date eligible to join pension scheme",

  //Absence details
  hasEmployeeAssessedByOccHealth:
    "Has the employee been assessed by an occupational health provider?",
  hasChangesToRemainAtWorkMade:
    "Have any changes or adjustments to the employee’s duties, working hours or work environment been made to help them remain at work?",
  changesToRemainAtWorkDetails: "Details",
  hasReturnToWorkBeenDiscussed:
    "Has a return to work date been discussed or agreed, or is the employee already working on an amended basis?",
  returnToWorkDetails: "Details",
  hasOtherFactors:
    "Were/are there any other factors or reasons impacting the employee’s absence, e.g. workplace issues, disciplinary, family circumstances, etc?",
  otherFactorsDetails: "Details",

  //Payment details
  claimFor: "Who is this claim for?",
  agreementWithUnumToUseBankDetails:
    "Do you have a prior agreement with Unum to use a standard policyholder bank account for your claim payments?",
  bankAccount: "Bank name",
  accountName: "Account name",
  sortCode: "Sort code",
  accountNumber: "Account number",

  //CI
  criticalDiagnosis: "Critical illness event (if known)",
  empInsuredsalary: "Employee's insured salary",
  insuredBenefit: "Insured benefit",
  pastDate: "1900-01-01",
  currencyTypePaidIn: "In which currency is your employee paid?",
  currencyTypeInsuredBenefit: "Currency for insured benefit",

  //Vocab Rehab support details
  requestRehabSupport:
    "Do you want to request support from the vocational rehabilitation team?",
  discussedReferralWithEmployee:
    "Have you discussed this referral with the employee?",
  whatSupportAreYouLookingFor: "What support are you looking for?",
  whatSupportAreYouLookingForEmp:
    "Please select the support you require below. The services available are:",
  hrContactName: "HR contact name",
  hrEmailAddress: "HR email address",
  hrPhoneNumber: "HR phone number",
  lineManagerName: "Line manager name",
  lineManagerEmailAddress: "Line manager email address",
  lineManagerPhoneNumber: "Line manager phone number",
  reasonForReferralText:
    "If known, please provide a brief summary of the reason for referring the employee",
  insuredEarning: "Insured earnings (if different from basic annual salary)",
  hasBeenAbleToWFH: "Is the employee able to work from home?",
  isMedicalCertificateNeeded:
    "Please check the box if the employee is required to hold a licence or certificate which is only issued when they meet required medical standards",
  primaryContact: "Primary Contact",
  //GL Payment Details
  cntctName: "Contact name",
  ukAccountName: "UK account name",
  payDirectBeneficiaries: "Pay direct to beneficiaries",
  schemeName: "Scheme name",
  //GL _Eligibility

  employedAtDateOfDeath:
    "Was the deceased still employed at their date of death",

  isOnFixedTermContract: "Was the deceased on a fixed term contract",
  contractEndDate: "Date contract would have ended",
  insuredSalary: "Insured salary",
  lumpSumInsured: "Lump sum insured",
  isPensionMembershipPartOfScheme:
    "Is pension membership part of the scheme's eligibility?",
  eligibleToJoinPensionSchemeDate: "Date eligible to join pension scheme",
  joinPensionDate: "Dated joined pension scheme",
  claimForDeathInServicePension:
    "Are you claiming for a death in service pension",
  pensionSupplDocInfo:
    "Please complete the dependants pension supplement, click the help icon to see information on this, including a link to the form",
};

// if you have a title that only applies to one product,
//add the product backend symbol to the end of the model in full caps
//e.g. eligibilityModelGIPVRS will only apply its title for GIPVRS with model eligibility
export const titles: any = {
  employeeDetailsModel: "Employee details",
  eligibilityModel: "Eligibility details",
  eligibilityModelGIPVRS: "Other details",
  absenceDetailsModel: "Absence details",
  workDetailsModel: "Work details",
  paymentDetailsModel: "Payment details",
  vocationalRehabSupportModel: "Vocational rehabilitation support",
  vocationalRehabSupportModelGIPVRS: "Employee support services",
  vocationalRehabSupportModelGIP: "Absence management support",
  claimSummary: "Summary",
  employeeDetailsModelGLI: "Deceased details",
  bespokeQuestionsModels: "Client questions",
  claimDetailsModel: "Claim details",
};

export const getRequiredMessage = (field: string) => {
  return `${labelsObj[field]} is required.`;
};

export const getValidMessage = (field: string) => {
  return `Please enter valid ${labelsObj[field].toLowerCase()}.`;
};

export const getMinMessage = (field: string, type: string, min: string) => {
  if (type === "string") {
    return `${labelsObj[field]} cannot be less than ${min} chars.`;
  } else if (type === "date") {
    return `${labelsObj[field]} cannot be less than ${min}.`;
  } else {
    return `${labelsObj[field]} cannot be less than ${min}.`;
  }
};

export const getMaxMessage = (field: string, type: string, max: string) => {
  if (type === "string") {
    // return `${labelsObj[field]} cannot be more than ${max} chars.`;
    return "Character limit exceeded";
  } else if (type === "date") {
    return `${labelsObj[field]} cannot be in the future.`;
  } else {
    return `${labelsObj[field]} cannot be more than ${max}.`;
  }
};

export const LEAVE_DIALOG_TITLE = "Are you sure you want to leave?";
export const LEAVE_DIALOG_TEXT =
  "If you leave now, any data entered on this page will be lost. To save the data on this page first, please complete all fields and press the continue button, then you can exit the form and the data will be saved. You can access partially completed claims from your dashboard. ";
export const COMN_RADIO_GRP_ERR_MSG = "Please select one of the radio buttons.";
export const COMN_INPUT_MAX_500 = "Text cannot be more than 500 characters.";
export const BRIEF_TEXT_LABEL = "Please give brief details";
export const REFERRAL_TEXT_ERR_MSG =
  "Please provide some information on the reason for the referral";

export const previousPopup = {
  title: "Are you sure you want to go back?",
  message:
    "If you go back before you press continue, " +
    "any data you have entered on this page will " +
    "not be saved. To save data on this page first, " +
    "please complete all fields and press the continue button. " +
    "You can then use the previous button without losing data. ",
};

export const currencyFields = [
  "insuredEarning",
  "basicAnnualSalary",
  "insuredSalary",
  "lumpSumInsured",
  "empInsuredsalary",
  "insuredBenefit",
];

export const showCurrencySymbol = (key: string, product: string) => {
  return noSymbolCurrencyFields.some(
    (obj) => obj.key === key && obj.product === product
  );
};

const noSymbolCurrencyFields = [
  { key: "basicAnnualSalary", product: "GIP" },
  { key: "insuredEarning", product: "GIP" },
  { key: "empInsuredsalary", product: "GCI" },
  { key: "insuredBenefit", product: "GCI" },
  { key: "insuredSalary", product: "GLI" },
  { key: "lumpSumInsured", product: "GLI" },
];

//Error message
export const USER_ASSIGN_ERROR =
  "An error occurred. We can't change the user at this time. Please refresh to try again or contact Unum on ";


export const ADMIN_UNASSIGNED_USER_NOTIFICATION =
  "There are unassigned claims/cases on your account. Please filter the table below by ‘unassigned’ and allocate a user to each case or claim.";

export const placeholderLabelText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, " +
  "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
  "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. " +
  "Duis aute irure dolor in reprehenderit in"