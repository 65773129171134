import { FC } from "react";
import {
  CarouselItemContainer,
  CarouselItemHeader,
  CarouselItemHeaderContainer,
  CarouselItemImage,
  CarouselItemImageContainer,
  CarouselItemSubHeader,
} from "./CarouselItem.styles";
import { carouselItemType } from "../../types/CarouselItemTypes";
import { getDateDMY } from "utils/util";
import podcastImage from "../../assets/icons/sound-waves.png";


type CarouselItemProps = {
  itemType: carouselItemType;
  header?: string;
  date?: string;
  image?: { src?: string; alt?: string };
  id: string;
  onSelect?: (id: string) => void;
};

const CarouselItem: FC<CarouselItemProps> = ({
  header,
  date,
  image,
  id,
  onSelect,
}) => {
  return (
    <CarouselItemContainer
      isClickable={!!onSelect}
      onClick={() => {
        onSelect && onSelect(id);
      }}
    >
      <CarouselItemImageContainer>
        <CarouselItemImage
          src={image?.src ? image.src : podcastImage}
          alt={image?.alt ? image.alt : "Podcast sound wave"}
        />
      </CarouselItemImageContainer>
      <CarouselItemHeaderContainer>
        <CarouselItemHeader>{header}</CarouselItemHeader>
        <CarouselItemSubHeader>
          {getDateDMY(new Date(date as string))}
        </CarouselItemSubHeader>
      </CarouselItemHeaderContainer>
    </CarouselItemContainer>
  );
};

export default CarouselItem;
