import styled, { css } from "styled-components";

interface logoProps {
  applyStyle: boolean;
  colour: string;
  sizeVariant?: boolean;
}

export const ApplyBorder = styled.div<logoProps>`
  ${({ applyStyle, colour, sizeVariant }) =>
    applyStyle &&
    css`
  color: ${({ theme }) => theme.colours.unumBlue[400]};
  padding-right:${sizeVariant ? "10px" : "0px"};
  border-right: 1px solid ${({ theme }) =>
    colour === "blue"
      ? theme.colours.unumBlue[400]
      : theme.colours.neutral[100]};
  Alignments: Horizontal;
  Vertical
  `}
`;
