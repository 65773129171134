import styled, { css } from "styled-components";

interface FooterProps {
  variant: "primary" | "secondary";
}

export const FooterMain = styled.footer<FooterProps>`
  ${({ variant, theme }) =>
    variant === "primary" &&
    css`
      background-color: #004470;
      color: #ffffff;
    `}

  ${({ variant, theme }) =>
    variant === "secondary" &&
    css`
    background-color:#F8F8F8; 
    drop-shadow(0px 2px 4px 0px #0F2835);
    color: #2E2E2E;
  `}
`;
export {};
