import APP_URL_CONSTS from "../../navigation/AppUrls";
import { products } from "../../types/enums/Products/Products";

export type IPage = {
  url: string;
  label: string;
  order: number;
};

const EMPLOYEE_DETAILS = "Employee details";
const ELIGIBILITY = "Eligibility details";
const OTHER_DETAILS = "Other details";
const WORK_DETAILS = "Work details";
const ABSENCE_DETAILS = "Absence details";
const PAYMENT_DETAILS = "Payment details";
const ABSENCE_MANAGEMENT_SUPPORT = "Absence management support";
const CLIENT_QUESTIONS = "Client questions";
const SUMMARY = "Summary";
const DECEASED_DETAILS = "Deceased details";
const CLAIM_DETAILS = "Claim details";
const EMPLOYEE_SUPPORT_SERVICES = "Employee support services";

export const gipFormPages: IPage[] = [
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.EMPLOYEE_DETAILS}`,
    label: EMPLOYEE_DETAILS,
    order: 0,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.ELIGIBILITY_DETAILS}`,
    label: ELIGIBILITY,
    order: 1,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.WORK_DETAILS}`,
    label: WORK_DETAILS,
    order: 2,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.ABSENCE_DETAILS}`,
    label: ABSENCE_DETAILS,
    order: 3,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.PAYMENT_DETAILS}`,
    label: PAYMENT_DETAILS,
    order: 4,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.VOC_REHB_SUPPORT}`,
    label: ABSENCE_MANAGEMENT_SUPPORT,
    order: 5,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLIENT_QUESTIONS}`,
    label: CLIENT_QUESTIONS,
    order: 6,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLAIM_SUMMARY}`,
    label: SUMMARY,
    order: 7,
  },
];

export const glFormPages: IPage[] = [
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.DECEASED_DETAILS}`,
    label: DECEASED_DETAILS,
    order: 0,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.ELIGIBILITY_DETAILS}`,
    label: ELIGIBILITY,
    order: 1,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.PAYMENT_DETAILS}`,
    label: PAYMENT_DETAILS,
    order: 2,
  },

  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLAIM_SUMMARY}`,
    label: SUMMARY,
    order: 3,
  },
];

export const rehabFormPages: IPage[] = [
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.EMPLOYEE_DETAILS}`,
    label: EMPLOYEE_DETAILS,
    order: 0,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.OTHER_DETAILS}`,
    label: OTHER_DETAILS,
    order: 1,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.ABSENC_MNGT_SPRT}`,
    label: EMPLOYEE_SUPPORT_SERVICES,
    order: 2,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLAIM_SUMMARY}`,
    label: SUMMARY,
    order: 3,
  },
];

export const cicFormPages: IPage[] = [
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.EMPLOYEE_DETAILS}`,
    label: EMPLOYEE_DETAILS,
    order: 0,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLAIM_DETAILS}`,
    label: CLAIM_DETAILS,
    order: 1,
  },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLAIM_SUMMARY}`,
    label: SUMMARY,
    order: 2,
  },
];

export const getFormPagesByProduct = (product: string): IPage[] | null => {
  switch (product.toUpperCase()) {
    case products.CIC.backend: {
      return cicFormPages;
    }
    case products.GIP.backend: {
      return gipFormPages;
    }
    case products.GL.backend: {
      return glFormPages;
    }
    case products.REHAB.backend: {
      return rehabFormPages;
    }
    default: {
      return null;
    }
  }
};

export const setMargin = (label: string, styles: any) => {
  if (label === EMPLOYEE_DETAILS) {
    return styles.EmployeeDetails;
  } else if (label === ELIGIBILITY) {
    return styles.Eligibility;
  } else if (label === WORK_DETAILS) {
    return styles.WorkDetails;
  } else if (label === ABSENCE_DETAILS) {
    return styles.AbsenceDetails;
  } else if (label === PAYMENT_DETAILS) {
    return styles.PaymentDetails;
  } else if (label === ABSENCE_MANAGEMENT_SUPPORT) {
    return styles.VocationalRehabSupport;
  } else if (label === EMPLOYEE_SUPPORT_SERVICES) {
    return styles.AbsenceManagementSupport;
  } else if (label === CLIENT_QUESTIONS) {
    return styles.BespokeQuestions;
  } else if (label === SUMMARY) {
    return styles.Summary;
  } else if (label === CLAIM_DETAILS) {
    return styles.ClaimDetails;
  } else {
    return "";
  }
};
