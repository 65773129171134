import { Alert, Dialog, IconButton } from "@mui/material";
import {
  TncActions,
  TncActionsWrapper,
  TncHeader,
  TncWrapper,
  TnCData,
  TnCButton,
} from "./TermAndConditionsDialog.styles";
import { FC, useRef, RefObject } from "react";
import PageScrollSkip from "components/PageScrollSkip/PageScrollSkip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import LoadingCircles from "components/LoadingCircles/LoadingCircles";

type TermAndConditionsDialogProps = {
  isOpen: boolean;
  setIsOpen: (toogle: boolean) => void;
  tncDocument: string;
  isLoading: boolean;
  tncError?: string;
  setTncError?: (value: undefined | string) => void;
};

const TermAndConditionsDialog: FC<TermAndConditionsDialogProps> = ({
  isOpen,
  setIsOpen,
  tncDocument,
  isLoading,
  tncError,
  setTncError,
}) => {
  const tncWrapperRef = useRef<HTMLElement>(null);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        overflow: "hidden",
        "& .MuiPaper-root": {
          padding: "0px 0px 0px 0px !important",
          maxWidth: "1000px !important",
          width: "100% !important",
        },
      }}
    >
      <TncWrapper ref={tncWrapperRef as RefObject<HTMLDivElement>}>
        <TncHeader>
          <div>Terms of use</div>

          <IconButton
            sx={{ height: "25px", width: "25px" }}
            size="small"
            onClick={() => {
              setIsOpen(false);
            }}
            data-testid="CrossButton"
          >
            <FontAwesomeIcon icon={faX} color="#2E2E2E" />
          </IconButton>
        </TncHeader>
        {tncError && (
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            onClose={
              setTncError
                ? () => {
                    setTncError(undefined);
                  }
                : undefined
            }
          >
            Sorry somthing went wrong
          </Alert>
        )}
        {isLoading ? (
          <div style={{ padding: "20px" }}>
            <LoadingCircles isLoading withoutDialogue />
          </div>
        ) : (
          <TnCData
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tncDocument || ""),
            }}
          />
        )}

        <TncActionsWrapper>
          <PageScrollSkip refInput={tncWrapperRef} />
          <TncActions>
            <TnCButton
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </TnCButton>
          </TncActions>
        </TncActionsWrapper>
      </TncWrapper>
    </Dialog>
  );
};

export default TermAndConditionsDialog;
