export const homepageCodenames = {
    homepage: { label: "Homepage", codename: "homepage" },
    rehabBanner: {
        label: "Rehabilitation promotionbal banner",
        codename: "rehabilitation___return_to_work_promo_banner",
    },
    groupRiskProductPromo: {
        label: "Group Risk Product Promo",
        codename: "group_risk_product",
    },
    cashPlanProductPromo: {
        label: "Cash Plan Product Promo",
        codename: "cash_plan_product",
    },
    latestArticalsFeed: {
        label: "Latest Articles Feed",
        codename: "latest_articles_feed",
    },
    podcastsFeed: {
        label: "podcasts_feed",
        codename: "podcasts_feed",
    },
    wellbeingWebinarsFeed: {
        label: "Wellbeing Webinars Feed",
        codename: "wellbeing_webinars_feed",
    },
    theUnumStory: {
        label: "The Unum Story",
        codename: "the_unum_story_section"
    },
    healthCashPlan: {
        label: "Health Cash Plan",
        codename: "health_cash_plan_section"
    }
};