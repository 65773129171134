import { AxiosRequestConfig } from "axios";
import apiCall from "../services/apiWrapper";
import { useAppSelector } from "./reduxhooks";
import { compData } from "../utils/util";

const useForgeRock = () => {
  const company =
    useAppSelector((state) => state?.ClaimSlice?.companyName) || "";

  const getProduct = (product: any) => {
    if (typeof product === "number") {
      switch (product) {
        case 1:
          return "GIP";
        case 4:
          return "GIPVRS";
        case 3:
          return "GLI";
        case 2:
          return "GCI";
        default:
          return "";
      }
    } else if (typeof product === "string") {
      return product;
    } else {
      return "";
    }
  };

  const APICall = async (params: AxiosRequestConfig) => {
    const data: compData = {
      company,
      product: getProduct(params?.data?.productTypeModel),
    };

    return apiCall(params, data);
  };

  return { APICall };
};

export default useForgeRock;
