import { FC, useState } from "react";
import { ICarouselSection } from "../../../models/cms/kontent_model/Generic/interfaces";
import Skeleton from "@mui/material/Skeleton/Skeleton";
import { Tabs } from "../../../components/Storybook/Tabs/Tabs";
import Carousel from "../../../components/Storybook/Carousel/Carousel";
import CarouselItem from "components/CarouselItem/CarouselItem";
import { UiCarouselItemTypeEnum } from "../../../types/enums/CarouselEnums";

type carouselSectionDataProps = {
  isCmsLoading: boolean;
  carouselSectionData?: ICarouselSection;
};

const CarouselSectionSkeleton = () => (
  <div
    style={{
      display: "flex",
      gap: "40px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Skeleton
      variant="rounded"
      width={"40%"}
      height={100}
      data-testid="carouselSection-Skeleton"
    />
    <div style={{ display: "flex", gap: "25px", width: "100%" }}>
      <Skeleton
        variant="rounded"
        width={"35%"}
        height={320}
        data-testid="carouselSection-Skeleton"
      />
      <Skeleton
        variant="rounded"
        width={"16.25%"}
        height={320}
        data-testid="carouselSection-Skeleton"
      />
      <Skeleton
        variant="rounded"
        width={"16.25%"}
        height={320}
        data-testid="carouselSection-Skeleton"
      />
      <Skeleton
        variant="rounded"
        width={"16.25%"}
        height={320}
        data-testid="carouselSection-Skeleton"
      />
      <Skeleton
        variant="rounded"
        width={"16.25%"}
        height={320}
        data-testid="carouselSection-Skeleton"
      />
    </div>
  </div>
);

const CarouselSection: FC<carouselSectionDataProps> = ({
  isCmsLoading,
  carouselSectionData,
}) => {
  const [currentCategory, setCurrentCategory] =
    useState<keyof ICarouselSection>("wellbeingWebinars");

  return (
    <>
      {isCmsLoading || !carouselSectionData ? (
        <CarouselSectionSkeleton />
      ) : (
        <div
          style={{
            background: "#F8F8F8",
            padding: "50px 40px",
            width: "calc(100% + 80px)",
            marginLeft: "-40px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <Tabs
              variant="scrollable"
              labels={[
                {
                  id: carouselSectionData.wellbeingWebinars.id,
                  label: carouselSectionData.wellbeingWebinars.title,
                },
                {
                  id: carouselSectionData.articles.id,
                  label: carouselSectionData.articles.title,
                },
                {
                  id: carouselSectionData.podcasts.id,
                  label: carouselSectionData.podcasts.title,
                },
              ]}
              onClick={(id: number) => {
                if (id === carouselSectionData.wellbeingWebinars.id) {
                  setCurrentCategory("wellbeingWebinars");
                }
                if (id === carouselSectionData.articles.id) {
                  setCurrentCategory("articles");
                }
                if (id === carouselSectionData.podcasts.id) {
                  setCurrentCategory("podcasts");
                }
              }}
            ></Tabs>
          </div>
          <div style={{ marginTop: "40px", marginRight: "-40px" }}>
            <Carousel
              justifyContent="space-between"
              maxSlideWidth="250px"
              autoPlayDelay={6000}
              isAutoPlay
              slidesPerFrame={4.5}
              slideGap="24px"
              ShowLeftSlideEndFade
              breakpoints={[
                {
                  breakpointType: "min",
                  breakAtPixels: 1325,
                  slidesPerFrame: 4.5,
                },
                {
                  breakpointType: "min",
                  breakAtPixels: 1000,
                  slidesPerFrame: 3.5,
                },
                {
                  breakpointType: "min",
                  breakAtPixels: 900,
                  slidesPerFrame: 2.5,
                },
                {
                  breakpointType: "min",
                  breakAtPixels: 760,
                  slidesPerFrame: 1.5,
                },
              ]}
              slides={carouselSectionData[currentCategory].feed.map(
                (item, index) => (
                  <CarouselItem
                    key={index}
                    itemType={item.eventType}
                    id={item.id}
                    image={{
                      src: item.imageURL,
                      alt: item.description,
                    }}
                    date={item.date}
                    header={item.title}
                    onSelect={(id) => {
                      window.open(
                        `${process.env.REACT_APP_UnumCOUK_URL}?id=${id}`,
                        "_blank"
                      );
                    }}
                  />
                )
              )}
              showPagination={true}
              PaginationContent={{
                header: carouselSectionData[currentCategory].title,
                subHeader: carouselSectionData[currentCategory].subtitle,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CarouselSection;
