import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setCMSLoading } from '../redux/slices/cmsLoadingSlice';
import { getCMSData, getUnumUkCMSData } from "../services/apis";
import useForgeRock from "./useForgeRock";

type CMSSoruce = "EmployerPortal" | "UnumUK" | undefined;

const useFetchCMSData = (
  CMSSoruce: CMSSoruce = "EmployerPortal",
  params?: {}
) => {
  const { APICall } = useForgeRock();

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    let error: any = null;
    let response: any = null;
    const axiosRequest =
      CMSSoruce === "EmployerPortal" ? getCMSData : getUnumUkCMSData(params);

    try {
      dispatch(setCMSLoading(true));

      response = await APICall({ ...axiosRequest });

      if (response) {
        return { response, error: null };
      } else {
        error = "CMS response was null";
        return { response: null, error };
      }
    } catch (errorMsg) {
      return { response: null, error: errorMsg };
    } finally {
      dispatch(setCMSLoading(false));
    }
  }, [APICall, dispatch, CMSSoruce]);

  return { fetchData };
};

export default useFetchCMSData;
