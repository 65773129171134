import {
  kontentCarouselItemEnum,
  UiCarouselItemTypeEnum,
  kontentEventEnum,
} from "types/enums/CarouselEnums";
import { ICarouselItem } from "../Generic/interfaces";
import { carouselItemType } from "types/CarouselItemTypes";

export const getItemType = (type: string): carouselItemType => {
  switch (type) {
    case kontentCarouselItemEnum.awarenessDate:
      return UiCarouselItemTypeEnum.awarenessDate;
    case kontentCarouselItemEnum.usefulResource:
      return UiCarouselItemTypeEnum.usefulResource;
    case kontentCarouselItemEnum.webinar:
      return UiCarouselItemTypeEnum.webinar;
  }
  return UiCarouselItemTypeEnum.podcast;
};

export const getImage = (codename: string, modularContent: any) => {
  return {
    url: modularContent?.[codename]?.elements?.image?.value?.[0]?.url,
    description:
      modularContent?.[codename]?.elements?.image?.value?.[0]?.description,
  };
};

export const mapEvent = (
  elements: any,
  modularContent: any,
  id: string
): ICarouselItem => {
  const image = getImage(elements.image.value[0], modularContent);
  return {
    id: id,
    date: elements.date.value,
    title: elements.title.value,
    eventType: getItemType(
      elements["event_types__event_type"].value[0].codename
    ),
    topics: elements.topic.value.map(
      (topic: { name: string; codename: string }) => topic.codename
    ),
    imageURL: image.url,
    description: image.description,
  };
};

export const mapPodcast = (elements: any, id: string): ICarouselItem => {
  return {
    id: id,
    date: elements.date.value,
    title: elements.name.value,
    eventType: UiCarouselItemTypeEnum.podcast,
    topics: elements.topics.value.map(
      (topic: { name: string; codename: string }) => topic.codename
    ),
    imageURL: undefined,
  };
};

export const mapCarouselItems = (response: {
  items: any[];
  modular_content: any;
}): ICarouselItem[] => {
  if (!response) {
    return [];
  }
  const items = response.items;

  const mappedItems: ICarouselItem[] = items
    .filter(
      (item) =>
        item.system.type === kontentEventEnum.event ||
        item.system.type === kontentEventEnum.podcast
    )
    .map((item) => {
      const elements = item.elements;
      return item.system.type === kontentEventEnum.event
        ? mapEvent(elements, response["modular_content"], item.system.id)
        : mapPodcast(elements, item.system.id);
    });
  return mappedItems;
};
