// src/components/VideoCard.tsx
import React from 'react';
import { VideoBlock } from "./Video.styles";
import ReactPlayer from "react-player/lazy";

type VideoUploaderProps = {
  title?: string;
  videoUrl: string | undefined;
  thumbnailUrl?: string;
  maxVideoWidth: number;
};

const VideoUploader: React.FC<VideoUploaderProps> = ({
  title,
  videoUrl,
  thumbnailUrl,
  maxVideoWidth,
}) => {
  return (
    <VideoBlock>
      <div
        style={{
          position: "relative",
          paddingTop: "56.25%",
          width: "100%",
          height: "100%",
          maxWidth: maxVideoWidth,
        }}
      >
        <ReactPlayer
          style={{ position: "absolute", top: 0, left: 0 }}
          url={videoUrl}
          controls={true}
          height={"100%"}
          width={"100%"}
        />
      </div>
    </VideoBlock>
  );
};

export default VideoUploader;