import { FC } from "react";
import { CreatePluginType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import {
  CarouselViewport,
  CarouselContainer,
  CarouselSlide,
  CarouselNavagation,
  CarouselDot,
  CarouselWrapper,
  CarouselFade,
} from "./Carousel.styles";
import { useCarouselNavigation } from "./hooks/useCarouselNavigation";
import Autoplay from "embla-carousel-autoplay";
import Fade from "embla-carousel-fade";
import { LoosePluginType } from "embla-carousel/components/Plugins";
import Pagination from "./Pagination/Pagination";
import { breakpoint, CarouselProps } from "./CarouselTypes";

export const DefaultBreakpoints = (slidesPerFrame: number): breakpoint[] => {
  const deciamls = slidesPerFrame % 1;
  const wholeNumber = slidesPerFrame - deciamls;
  return [
    {
      breakpointType: "min",
      breakAtPixels: 768,
      slidesPerFrame: Math.ceil(wholeNumber / 2) + deciamls,
    },
    {
      breakpointType: "min",
      breakAtPixels: 992,
      slidesPerFrame: slidesPerFrame,
    },
  ];
};

const Carousel: FC<CarouselProps> = ({
  slides,
  slidesPerFrame = 1,
  slideGap = "1rem",
  isFade = false,
  isAutoPlay = false,
  autoPlayDelay = 1000,
  loop = false,
  slidesToScroll = "auto",
  showPagination = false,
  PaginationContent,
  maxSlideWidth,
  breakpoints = DefaultBreakpoints(slidesPerFrame),
  justifyContent = "unset",
  ShowLeftSlideEndFade = false,
}) => {
  let plugins: CreatePluginType<LoosePluginType, {}>[] | undefined = [];

  if (isFade) {
    plugins.push(Fade());
    slidesPerFrame = 1;
    slidesToScroll = 1;
  }

  if (isAutoPlay) {
    plugins.push(Autoplay({ delay: autoPlayDelay }));
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop, slidesToScroll },
    plugins
  );

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useCarouselNavigation(emblaApi);

  return (
    <CarouselWrapper $justifyContent={justifyContent}>
      {showPagination ? (
        <Pagination emblaApi={emblaApi} PaginationContent={PaginationContent} />
      ) : null}

      <div
        style={{
          padding: "10px 0px 10px 0px",
          width: "fit-content",
          maxWidth: "100%",
          position: "relative",
        }}
      >
        <CarouselFade $showFade={selectedIndex !== 0 && ShowLeftSlideEndFade} />
        <CarouselViewport
          $breakpoints={breakpoints}
          ref={emblaRef}
          $maxPageSize={maxSlideWidth}
          $slidesPerFrame={slidesPerFrame}
          $gap={slideGap}
        >
          <CarouselContainer
            $breakpoints={breakpoints}
            $gap={slideGap}
            $slidesPerFrame={slidesPerFrame}
          >
            {slides.map((slide, index) => (
              <CarouselSlide
                $breakpoints={breakpoints}
                key={index}
                $slidesPerFrame={slidesPerFrame}
                $gap={slideGap}
                $maxPageSize={maxSlideWidth}
                data-testid={"CarouselSlide"}
              >
                {slide}
              </CarouselSlide>
            ))}
          </CarouselContainer>
        </CarouselViewport>
        <CarouselNavagation role="tablist">
          {scrollSnaps.map((_, index) => (
            <CarouselDot
              key={index}
              onClick={() => onDotButtonClick(index)}
              $isActive={index === selectedIndex}
              aria-selected={index === selectedIndex}
              aria-label={"CarouselPagenation-" + index}
              data-testid={"CarouselPagenation"}
              role="tab"
            />
          ))}
        </CarouselNavagation>
      </div>
    </CarouselWrapper>
  );
};

export default Carousel;
