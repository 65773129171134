import Logo from "../Logo/Logo";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons"; // Assuming you've imported these icons

import style from "./Footer.module.css"; // Importing the CSS module
import { FooterMain } from "./Footer.styles";

interface FooterProps {
  colourScheme: "primary" | "secondary";
  showSocialIcons?: boolean;
  slimVariant?: boolean;
  menuItems?: { label: string; onClick: () => void }[];
}

const DefaultSlimMenu = [
  { label: "Terms of use", onClick: () => {} },
  {
    label: "Privacy notice",
    onClick: () => {
      window.open("https://www.unum.co.uk/privacy-notice", "Privacy-notice");
    },
  },
  {
    label: "Legal Notice",
    onClick: () => {

      window.open("https://www.unum.co.uk/legal-notice", "legal-notice");
    },
  },
  {
    label: "How we use cookies",
    onClick: () => {
      window.open("https://www.unum.co.uk/how-we-use-cookies", "cookies");
    },
  },
];

const Footer: React.FC<FooterProps> = ({
  colourScheme,
  showSocialIcons,
  slimVariant,
  menuItems = DefaultSlimMenu,
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterMain variant={colourScheme}>
      <footer className={style.F_Main}>
        <div className={style.F_wrapper}>
          <div className={style.F_LinkMain}>
            <div>
              <div>
                <Logo
                  size={"medium"}
                  colour={
                    colourScheme === "primary"
                      ? "white"
                      : colourScheme === "secondary"
                        ? "blue"
                        : "white"
                  }
                />
              </div>
              {showSocialIcons && (
                <div>
                  <ul className={style.F_IconList}>
                    <li className={style.F_Icon}>
                      <a
                        data-autoid="LINK_Footer_LinkedIn"
                        aria-label="LinkedIn link"
                        href="https://www.linkedin.com/company/unum-uk"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </li>
                    <li className={style.F_Icon}>
                      <a
                        data-autoid="LINK_Footer_Youtube"
                        aria-label="Youtube link"
                        href="https://www.youtube.com/user/unumuk"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {!slimVariant && (
              <div>
                <h2>Company</h2>
                <ul>
                  <li>
                    <a
                      data-autoid="LINK_Footer_About_Us"
                      href="https://www.unum.co.uk/about-us"
                      target="_blank"
                    >
                      About Unum
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Our_Team"
                      href="https://www.unum.co.uk/about-us/management-team"
                      target="_blank"
                    >
                      Our team
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Media"
                      href="https://www.unum.co.uk/about-us/media"
                      target="_blank"
                    >
                      Media
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Careers"
                      href="https://careers.unum.com/global/en"
                      target="_blank"
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Responsible_Business"
                      href="https://www.unum.co.uk/about-us/responsible-business"
                      target="_blank"
                    >
                      Responsible Business
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {!slimVariant && (
              <div>
                <h2>Support</h2>
                <ul>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Workplace_Wellbeing"
                      href="https://www.unum.co.uk/workplace-wellbeing-hub"
                      target="_blank"
                    >
                      Workplace Wellbeing Hub
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_wellbeing_calendar"
                      href="https://www.unum.co.uk/wellbeing-calendar"
                      target="_blank"
                    >
                      Wellbeing Calendar
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Absence_Management"
                      href="https://www.unum.co.uk/workplace-wellbeing-hub/absence-management"
                      target="_blank"
                    >
                      Absence management
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Contact_Us"
                      href="https://www.unum.co.uk/contact-us"
                      target="_blank"
                    >
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {!slimVariant && (
              <div>
                <h2>Legal</h2>
                <ul>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Legal_Notice"
                      href="https://www.unum.co.uk/legal-notice"
                      target="_blank"
                    >
                      Legal Notice
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Privacy_Notice"
                      href="https://www.unum.co.uk/privacy-notice"
                      target="_blank"
                    >
                      Privacy Notice
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Cookies"
                      href="https://www.unum.co.uk/how-we-use-cookies"
                      target="_blank"
                    >
                      How we use cookies
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Cofidence"
                      href="https://www.unum.co.uk/about-us/confidence-for-our-customers"
                      target="_blank"
                    >
                      Confidence for our customers
                    </a>
                  </li>
                  <li>
                    <a
                      data-autoid="LINK_Footer_Slavery"
                      href="https://www.unum.co.uk/docs/Modern-Slavery-Act-2015-Statement.pdf"
                      target="_blank"
                    >
                      Modern Slavery statement
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className={style.F_Divider}></div>
          {slimVariant && (
            <div className={style.F_SContent}>
              {menuItems.map((item, index) => {
                return (
                  <>
                    <button
                      key={index}
                      className={style.F_SItem}
                      onClick={() => {
                        item.onClick();
                      }}
                    >
                      {item.label}
                    </button>
                    {menuItems.length > 1 && index < menuItems.length - 1 && (
                      <div className={style.F_SDivider}></div>
                    )}
                  </>
                );
              })}
            </div>
          )}
          <div>
            <div
              className={style.F_CopyRight}
              data-autoid="LINK_Footer_Copyright_Date"
            >
              {`© ${currentYear} Unum Limited all rights reserved.`}
            </div>
            <div data-autoid="LINK_Footer_Copyright_Info">
              Unum Limited is authorised by the Prudential Regulation Authority
              and regulated by the Financial Conduct Authority and the
              Prudential Regulation Authority. Unum Limited is on the FCA
              Financial Services Register, Firm Reference Number: 110408.
              Registered and Head Office: Milton Court, Dorking, Surrey, RH4
              3LZ. Registered in England company number 983768.
            </div>
          </div>
        </div>
      </footer>
    </FooterMain>
  );
};
export default Footer;
