import { useCallback, useEffect, useState } from "react";
import useForgeRock from "../useForgeRock";
import { termsOfUseItem, termsOfUseResponse } from "types/TermsOfUse";
import { getTnCs } from "services/apis";

const useTermsandCondtions = () => {
  const { APICall } = useForgeRock();

  const [tncDocument, setTNCDocument] = useState("");
  const [loadingTnc, setLoadingTnc] = useState(true);
  const [errorLoadingTnc, setErrorloadingTnc] = useState<undefined | string>(
    undefined
  );

  const getLatestTnc = (termsOfuseList: termsOfUseItem[]) => {
    if (!termsOfuseList || termsOfuseList.length < 1) {
      return undefined;
    }
    termsOfuseList.sort(
      (a, b) =>
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    );
    return termsOfuseList[0];
  };

  const getTNCs = useCallback(async () => {
    try {
      setLoadingTnc(true);
      const TnCResponse: termsOfUseResponse = await APICall(getTnCs);
      if (TnCResponse.resultCode === -1) {
        setErrorloadingTnc("Terms and condtions failed to load");
        return;
      }
      if (!TnCResponse.response || TnCResponse.response.length < 1) {
        setErrorloadingTnc("Terms and condtions do not exist");
        return;
      }
      const latest = getLatestTnc(TnCResponse.response);
      if (!latest) {
        setErrorloadingTnc("Cant find the latest terms and condtions");
        return;
      }
      setTNCDocument(latest.document);
    } catch (e) {
      setErrorloadingTnc(
        "Terms and condtions failed to load due to an exception"
      );
      return;
    } finally {
      setLoadingTnc(false);
    }
  }, []);

  useEffect(() => {
    getTNCs();
  }, [getTNCs]);

  return { tncDocument, loadingTnc, errorLoadingTnc, setErrorloadingTnc };
};

export default useTermsandCondtions;
