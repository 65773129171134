import { FC, RefObject, useEffect, useRef, useState } from "react";
import { ScrollFade } from "./PageScrollSkip.style";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

type PageScrollSkipProps = {
  refInput: RefObject<HTMLElement>;
};
const PageScrollSkip: FC<PageScrollSkipProps> = ({ refInput }) => {
  const scroller = useRef(null);
  const [atBottom, setAtBottom] = useState(false);

  const scrollUp = () => {
    if (!refInput.current) {
      return null;
    }
    refInput.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollDown = () => {
    if (!refInput.current) {
      return null;
    }
    refInput.current.scroll({
      top: refInput.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const determineStickyState = () => {
      if (refInput.current) {
        const { scrollTop, scrollHeight, clientHeight } = refInput.current;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight;
        setAtBottom(isNearBottom);
      }
    };

    const parentContainer = refInput.current;
    if (parentContainer) {
      parentContainer.addEventListener("scroll", determineStickyState);
      return () => {
        parentContainer.removeEventListener("scroll", determineStickyState);
      };
    }
  }, [refInput]);

  return (
    <>
      <ScrollFade showFade={!atBottom} ref={scroller}>
        {atBottom ? (
          <IconButton onClick={scrollUp} data-testid="UpButton">
            <FontAwesomeIcon icon={faChevronUp} color="#015294" />
          </IconButton>
        ) : (
          <IconButton onClick={scrollDown} data-testid="DownButton">
            <FontAwesomeIcon icon={faChevronDown} color="#015294" />
          </IconButton>
        )}
      </ScrollFade>
    </>
  );
};

export default PageScrollSkip;
