import { styled, css } from "styled-components";

type PaginationButtonProps = {
  isDisabled: boolean;
};

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: unset;
    width: 15%;
  }
`;

export const PaginationHeader = styled.div`
  font-family: Ubuntu;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  color: #015294;
`;

export const PaginationSubHeader = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const PaginationButtonWrapper = styled.div`
  gap: 16px;
  margin-top: 24px;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

export const PaginationButton = styled.button<PaginationButtonProps>`
  ${({ theme, isDisabled }) => css`
  all: unset;
  border-radius: 1000px;
  cursor:pointer;
  background-color: #015294;
  color: #FFFFFF;
  height: 35px;
  width: 35px;
      display: flex;
    justify-content: center;
    align-items: center;
      ${
        isDisabled &&
        css`
          cursor: unset;
          background-color: #cdcdcd;
        `
      }}
    `}
  &:focus-visible {
    outline: 1px solid black;
    outline-offset: 1px;
  }
`;
