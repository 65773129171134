import { FC } from "react";
import DialogWithClose from "../DialogWithClose/DialogWithClose";
import Button from "@mui/material/Button";

type primaryBtnColorTypes =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;

type DialogProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  primaryBtnText?: string;
  secndryBtnText?: string;
  primaryBtnAction?: () => void;
  secndryBtnAction?: () => void;
  primaryBtnColor: primaryBtnColorTypes;
};

const Dialog: FC<DialogProps> = ({
  open,
  onClose,
  title,
  message,
  primaryBtnText,
  secndryBtnText,
  primaryBtnColor,
  primaryBtnAction,
  secndryBtnAction = onClose,
}) => {
  return (
    <DialogWithClose
      open={open}
      onClose={onClose}
      aria-labelledby="Dialog-title"
      aria-describedby="Dialog-description"
      sx={{ "& .MuiPaper-root": { padding: "0px !important" } }}
    >
      <h6
        style={{
          padding: "14px",
          fontWeight: 700,
          borderBottom: "1px solid #cccccc",
        }}
      >
        {title}
      </h6>
      <p
        style={{
          padding: "14px",
          borderBottom: "1px solid #cccccc",
          fontSize: "14px",
        }}
      >
        {message}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "14px",
          background: "#f6f8fa",
        }}
      >
        <Button
          sx={{ backgroundColor: "#ffffff", padding: "2px 4px", width: "80px" }}
          variant="outlined"
          onClick={secndryBtnAction}
        >
          {secndryBtnText}
        </Button>

        <Button
          variant="contained"
          onClick={primaryBtnAction}
          color={primaryBtnColor}
          sx={
            primaryBtnColor === "success"
              ? {
                  padding: "2px 4px",
                  width: "80px",
                  marginLeft: "10px",
                  backgroundColor: "#0a6a36",
                  "&:hover": {
                    backgroundColor: "#1B5E20",
                  },
                }
              : {
                  marginLeft: "10px",
                }
          }
        >
          {primaryBtnText}
        </Button>
      </div>
    </DialogWithClose>
  );
};

export default Dialog;
