import React, { useState } from "react";
import { Box, StyledTabs, Tab } from "./Tabs.styles";
import { TabsProps } from "./Tabs.types";

function a11yProps(label: string, index: number) {
  return {
    id: `tab-${label}-${index}`,
    "aria-controls": `tab-${label}-${index}`,
    // Add data-testid and other required ids below
  };
}

export const Tabs = ({
  children,
  labels = [],
  variant = "standard",
  orientation = "horizontal",
  onClick,
  ...props
}: TabsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onClick && onClick(newValue);
  };

  return (
    <Box $variant={variant} $orientation={orientation}>
      <StyledTabs
        centered
        value={value}
        onChange={handleChange}
        orientation={orientation}
        variant={variant}
        allowScrollButtonsMobile
        scrollButtons={variant === "scrollable"}
        TabIndicatorProps={{
          sx: {
            display: "none",
          },
        }}
        {...props}
      >
        {labels?.map((label: { id: number; label: string }, i: number) => (
          <Tab
            value={label.id}
            key={label.label}
            sx={{
              mr:
                i !== labels.length - 1 && orientation === "horizontal" ? 2 : 0,
              mb: i !== labels.length - 1 && orientation === "vertical" ? 1 : 0,
            }}
            label={label.label}
            {...a11yProps(label.label, i)}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};
