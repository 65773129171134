import {
  PaginationWrapper,
  PaginationHeader,
  PaginationSubHeader,
  PaginationButtonWrapper,
  PaginationButton,
} from "./Pagination.styles";
import { useCarouselPagination } from "../hooks/useCarouselPagination";
import { EmblaCarouselType } from "embla-carousel";
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

type PaginationContent = {
  header?: string;
  subHeader?: string;
};

type PaginationProps = {
  emblaApi: EmblaCarouselType | undefined;
  PaginationContent?: PaginationContent;
};

const Pagination: FC<PaginationProps> = ({ emblaApi, PaginationContent }) => {
  const {
    onPrevButtonClick,
    onNextButtonClick,
    prevBtnDisabled,
    nextBtnDisabled,
  } = useCarouselPagination(emblaApi);
  return (
    <PaginationWrapper>
      {PaginationContent?.header ? (
        <PaginationHeader>{PaginationContent.header}</PaginationHeader>
      ) : null}
      {PaginationContent?.subHeader ? (
        <PaginationSubHeader>{PaginationContent.subHeader}</PaginationSubHeader>
      ) : null}
      <PaginationButtonWrapper>
        <PaginationButton
          onClick={onPrevButtonClick}
          isDisabled={prevBtnDisabled}
          aria-label="Previous"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </PaginationButton>
        <PaginationButton
          onClick={onNextButtonClick}
          isDisabled={nextBtnDisabled}
          aria-label="Next"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </PaginationButton>
      </PaginationButtonWrapper>
    </PaginationWrapper>
  );
};

export default Pagination;
